import { a as isAsyncIterable, b as isIterable, i as isPromise, c as isIterableIterator, d as asyncExtendedIterable, g as getNext } from './index-49c9a3af.js';

/**
 * Indicates if a value is a {@link MarshalledSourceReference}
 * @param value
 */
function isMarshalledSourceReference(value) {
    return (typeof value === "string" ||
        typeof value === "number" ||
        typeof value === "boolean" ||
        typeof value === "bigint");
}
/**
 * Indicates if a value is a {@link SourceReference}
 * @param value
 */
function isSourceReference(value) {
    return (typeof value === "symbol" ||
        isMarshalledSourceReference(value));
}

function isVNodeLike(value) {
    return typeof value === "object" || typeof value === "function";
}
/**
 * Indicates if a value is a {@link VNode}
 * @param value
 */
function isVNode(value) {
    return !!(isVNodeLike(value) &&
        isSourceReference(value.reference) &&
        (!value.children ||
            isAsyncIterable(value.children)) &&
        (!value.options ||
            typeof value.options === "object"));
}
/**
 * Indicates if a value is a {@link NativeVNode}
 * @param value
 */
function isNativeVNode(value) {
    function isNativeVNodeLike(value) {
        return isVNode(value);
    }
    return (isNativeVNodeLike(value) &&
        value.native === true);
}
function isScalarVNode(value, isSource) {
    function isScalarVNodeLike(value) {
        return isVNode(value);
    }
    return (isScalarVNodeLike(value) &&
        isSourceReference(value.source) &&
        value.scalar === true &&
        (typeof isSource === "function" ? isSource(value.source) : true));
}
/**
 * Indicates if a value is a {@link FragmentVNode}
 * @param value
 */
function isFragmentVNode(value) {
    return (isVNode(value) &&
        value.reference === Fragment);
}
/**
 * Indicates if a valid is a {@link MarshalledVNode}
 * @param value
 */
function isMarshalledVNode(value) {
    return (isVNodeLike(value) &&
        (!value.reference ||
            isSourceReference(value.reference)) &&
        // If we don't have children, then we have a normal VNode
        isIterable(value.children) &&
        (!value.options ||
            typeof value.options === "object"));
}

async function* asAsync(iterable) {
    yield* iterable;
}

function deferred() {
    let resolve = undefined, reject = undefined;
    const promise = new Promise((resolveFn, rejectFn) => {
        resolve = resolveFn;
        reject = rejectFn;
    });
    ok(resolve);
    ok(reject);
    return {
        resolve,
        reject,
        promise
    };
}
function ok(value) {
    if (!value) {
        throw new Error("Value not provided");
    }
}

function defaultTask(fn) {
    if (typeof queueMicrotask === "function") {
        queueMicrotask(fn);
    }
    else if (typeof setImmediate === "function") {
        setImmediate(fn);
    }
    else {
        setTimeout(fn, 0);
    }
}

function aggregateError(errors) {
    if (errors.length === 1) {
        return errors[0];
    }
    return new AggregateError(flatten(errors));
    function flatten(errors) {
        return errors.reduce((flat, error) => {
            if (error instanceof AggregateError) {
                return flat.concat(flatten(error.errors));
            }
            else {
                return flat.concat(error);
            }
        }, []);
    }
}

const Reuse = Symbol.for("@virtualstate/union/reuse");
function isReuse(value) {
    function isReuseLike(value) {
        return !!value;
    }
    return isReuseLike(value) && value[Reuse] === true;
}

const NextMicrotask = Symbol();
function map(iterable, map) {
    return [...doMap()];
    function* doMap() {
        for (const value of iterable) {
            yield map(value);
        }
    }
}
function filter(iterable, filter) {
    return [...doFilter()];
    function* doFilter() {
        for (const value of iterable) {
            if (filter(value))
                yield value;
        }
    }
}
async function* union(source, options = {}) {
    const task = options.queueTask || options.queueMicrotask || defaultTask;
    const states = new Map();
    const inFlight = new Map();
    const iterators = new WeakMap();
    const knownIterators = [];
    let iteratorsDone = false;
    let valuesDone = false;
    const sourceIterator = asAsync(source)[Symbol.asyncIterator]();
    let active = undefined;
    let iteratorsPromise = Promise.resolve();
    let iteratorAvailable = deferred();
    const iteratorsComplete = deferred();
    const errorOccurred = deferred();
    const errors = [];
    let results = [];
    let currentTaskPromise;
    if (options.eagerInitialTask) {
        nextTask();
    }
    let skipTaskDueToEmptyYield = false;
    try {
        cycle: do {
            const iteration = active = Symbol();
            iteratorsPromise = iteratorsPromise.then(() => nextLanes(iteration));
            if (!knownIterators.length) {
                await Promise.any([
                    iteratorAvailable.promise,
                    iteratorsComplete.promise
                ]);
                if (valuesDone) {
                    // Had no lanes
                    break;
                }
            }
            const updated = await waitForResult(iteration);
            if (errors.length) {
                break;
            }
            for (const result of updated) {
                const { iterator, promise } = result;
                const currentPromise = inFlight.get(iterator);
                if (promise !== currentPromise) {
                    onError(new Error("Unexpected promise state"));
                    break cycle;
                }
                inFlight.set(iterator, undefined);
                states.set(iterator, {
                    ...result,
                    value: result.done ? states.get(iterator)?.value : result.value,
                    resolvedIteration: iteration
                });
            }
            const finalResults = map(knownIterators, read);
            valuesDone = iteratorsDone && finalResults.every(result => result?.done);
            const onlyDone = !!updated.every(result => result.done);
            // Don't yield only done because the consumer already has received all these values
            if (onlyDone) {
                continue;
            }
            if (!valuesDone) {
                yield finalResults.map(result => result?.value);
            }
        } while (!valuesDone);
    }
    catch (error) {
        onError(error);
    }
    finally {
        active = undefined;
        await sourceIterator.return?.();
    }
    if (errors.length) {
        throw aggregateError(errors);
    }
    function read(iterator) {
        return states.get(iterator);
    }
    async function nextLanes(iteration) {
        while (active === iteration && !iteratorsDone) {
            const result = await sourceIterator.next();
            if (!isIteratorYieldResult(result)) {
                iteratorsDone = true;
                if (!knownIterators.length) {
                    valuesDone = true;
                }
                iteratorsComplete.resolve();
                await sourceIterator.return?.();
            }
            else if (result.value) {
                const sourceLane = result.value;
                const iterator = getIterator(sourceLane);
                if (options.reuseInFlight || isReuse(sourceLane)) {
                    iterators.set(sourceLane, iterator);
                }
                knownIterators.push(iterator);
                iteratorAvailable.resolve(iterator);
                iteratorAvailable = deferred();
            }
        }
        function getIterator(sourceLane) {
            if (options.reuseInFlight || isReuse(sourceLane)) {
                const currentIterator = iterators.get(sourceLane);
                if (currentIterator) {
                    const state = read(currentIterator);
                    if (state?.done !== true) {
                        // reuse
                        return currentIterator;
                    }
                }
            }
            return asAsync(sourceLane)[Symbol.asyncIterator]();
        }
        function isIteratorYieldResult(result) {
            return !result.done;
        }
    }
    async function waitForResult(iteration, emptyDepth = 0) {
        if (iteration !== active) {
            // Early exit if we actually aren't iterating this any more
            // I don't think this can actually trigger, but lets keep it around
            return [];
        }
        if (iteratorsDone && !knownIterators.length) {
            // No lanes to do anything, exit
            return [];
        }
        if (errors.length) {
            // We have a problem, exit
            return [];
        }
        const pendingIterators = filter(knownIterators, iterator => !read(iterator)?.done);
        // Grab onto this promise early so we don't miss one
        const nextIterator = iteratorAvailable.promise;
        if (!pendingIterators.length) {
            await nextIterator;
            return waitForResult(iteration);
        }
        const currentResults = await wait();
        if (!currentResults.length) {
            if (emptyDepth > 10000) {
                throw new Error("Empty depth over 10000");
            }
            // We have a new lane available, lets loop around and initialise its promise
            return waitForResult(iteration, emptyDepth + 1);
        }
        return currentResults;
        async function wait() {
            const promises = pendingIterators.map(next);
            let anyPromises;
            const waitForAtLeast = [
                currentTaskPromise,
                // Every iterator returned a result before the end of the task
                Promise.all(promises),
                // Early exit on any errors
                errorOccurred.promise,
                // We will be able to set up again next loop
                iteratorAvailable.promise
            ];
            if (!skipTaskDueToEmptyYield && !currentTaskPromise) {
                // We didn't just run a task, and we have no pending next task
                nextTask();
            }
            if (currentTaskPromise) {
                // The current task finished and we should yield at least one result
                waitForAtLeast.push(currentTaskPromise);
            }
            else {
                // If we are no waiting for a task, we are waiting for at least one result
                anyPromises = Promise.any(promises);
                waitForAtLeast.push(anyPromises);
            }
            const reason = await Promise.any(waitForAtLeast);
            if (reason === NextMicrotask) {
                currentTaskPromise = undefined;
                if (!results.length) {
                    skipTaskDueToEmptyYield = true;
                }
            }
            if (!results.length) {
                await Promise.any([
                    // We must wait for at least one result
                    anyPromises || Promise.any(promises),
                    // Or if there is a new iterator available, this iterator could
                    // potentially produce a result before all others
                    iteratorAvailable.promise,
                    errorOccurred.promise
                ]);
            }
            if (errors.length) {
                return [];
            }
            if (!results.length) {
                return [];
            }
            // Clone so that it only uses the values we have now
            const cloned = [...results];
            // Clear to start again
            results = [];
            return cloned;
        }
        async function next(iterator) {
            const current = inFlight.get(iterator);
            if (current)
                return current;
            const next = iterator.next()
                .then((result) => ({
                value: result.value,
                done: !!result.done,
                initialIteration: iteration,
                iterator,
                promise: next
            }))
                .catch((localError) => {
                onError(localError);
                return {
                    value: undefined,
                    done: true,
                    initialIteration: iteration,
                    iterator,
                    promise: next
                };
            })
                .then((result) => {
                results.push(result);
                return result;
            });
            inFlight.set(iterator, next);
            return next;
        }
    }
    function onError(error) {
        errors.push(error);
        errorOccurred.resolve();
    }
    function nextTask() {
        currentTaskPromise = new Promise(task).then(() => NextMicrotask);
    }
}

async function* childrenUnion(context, childrenGroups) {
    for await (const parts of union(childrenGroups, context)) {
        yield parts.reduce((updates, part) => part ? updates.concat(part.filter(Boolean)) : updates, []);
    }
}
async function* children(context, ...source) {
    async function* eachSource(source) {
        if (typeof source === "undefined") {
            return;
        }
        if (isPromise(source)) {
            return yield* eachSource(await source);
        }
        if (isFragmentVNode(source)) {
            if (!source.children) {
                return;
            }
            for await (const children of source.children) {
                yield* childrenUnion(context, children.map(eachSource));
            }
            return;
        }
        if (isVNode(source)) {
            return yield [
                source
            ];
        }
        // These need further processing through createVNodeWithContext
        if (isSourceReference(source) || isMarshalledVNode(source) || isIterableIterator(source)) {
            return yield* eachSource(context.createNode(source));
        }
        return yield* childrenUnion(context, asyncExtendedIterable(source).map(eachSource));
    }
    if (source.length === 1) {
        return yield* eachSource(source[0]);
    }
    else {
        return yield* childrenUnion(context, source.map(eachSource));
    }
}

// Access to re-assign a functional vnode child between children reads
const Child = Symbol("Function VNode Child");
const childrenContext = {
    createNode
};
function createNode(source, options, ...children$1) {
    /**
     * If the source is a function we're going to invoke it as soon as possible with the provided options
     *
     * The function _may_ return any other kind of source, so we need to start our process again
     */
    if (source instanceof Function) {
        return functionVNode(source);
    }
    /**
     * Only if the source is a promise we want to await it
     *
     * This may be wasteful, but the idea is that we wouldn't cause a next tick for no reason
     * Maybe this isn't the case if the value isn't a promise to start with ¯\_(ツ)_/¯
     */
    if (source && isPromise(source)) {
        return {
            source,
            reference: Fragment,
            children: replay(() => promiseGenerator(source))
        };
    }
    /**
     * If we have a fragment then we want to pass it back through our function so the next
     * statement is invoked to handle fragments with children
     */
    if (source === Fragment) {
        return createNode({ reference: Fragment, source }, options, ...children$1);
    }
    /**
     * If we already have a {@link VNode} then we don't and can't do any more
     */
    if (source && isVNode(source)) {
        let nextSource = source;
        /**
         * Extend our vnode options if we have been provided them
         * Each property that is not passed will match the initial property
         */
        if (options && source.options !== options) {
            nextSource = {
                ...nextSource,
                options: {
                    ...nextSource.options,
                    ...options
                }
            };
        }
        /**
         * Replace children if they have been given and the source doesn't already have children
         */
        if (children$1.length && !nextSource.children) {
            nextSource = {
                ...nextSource,
                children: replay(() => children(childrenContext, ...children$1))
            };
        }
        return nextSource;
    }
    /**
     * If we already have a {@link MarshalledVNode} then we need to turn its children into an async iterable
     * and ensure they're unmarshalled
     */
    if (source && isMarshalledVNode(source)) {
        return unmarshal(source);
    }
    const reference = getReference(options);
    /**
     * A source reference may be in reference to a context we don't know about, this can be resolved from
     * external contexts by rolling through the {@link VNode} state, or watching context events
     *
     * This could be used by analytics tracking for tags that show up
     *
     * Either way, if we have a source reference, we have a primitive value that we can look up later on
     */
    if (isSourceReference(source)) {
        return sourceReferenceVNode(reference, source, options, ...children$1);
    }
    /**
     * Here is our nice `IterableIterator` that allows us to produce multiple versions for the same source
     *
     * This specifically cannot be re-run twice, but this is expected to be returned from a function, where
     * functions can be run twice
     *
     * See {@link generator} for details
     */
    if (source && isIterableIterator(source)) {
        return {
            source,
            reference: Fragment,
            children: generator(Symbol("Iterable Iterator"), source)
        };
    }
    /**
     * This will cover `Array`, `Set`, `Map`, and anything else implementing `Iterable` or `AsyncIterable`
     *
     * We will create a `Fragment` that holds our node state to grab later
     */
    if (source && (isIterable(source) || isAsyncIterable(source))) {
        const childrenInstance = children(childrenContext, ...children$1);
        return {
            source,
            reference: Fragment,
            children: replay(() => children(childrenContext, asyncExtendedIterable(source).map(value => createNode(value, options, childrenInstance))))
        };
    }
    /**
     * Allows for `undefined`, an empty `VNode`
     */
    if (!source) {
        return { reference: Fragment, source };
    }
    /**
     * We _shouldn't_ get here AFAIK, each kind of source should have been dealt with by the time we get here
     */
    throw new Error("Unexpected VNode source provided");
    /**
     * Iterates through an `IterableIterator` to generate new {@link VNode} instances
     *
     * This allows an implementor to decide when their node returns state, including pushing new values _as they arrive_
     *
     * {@link getNext} provides an error boundary if the `IterableIterator` provides a `throw` function
     *
     * @param newReference
     * @param reference
     */
    async function* generator(newReference, reference) {
        let next;
        do {
            next = await getNext(reference, newReference);
            if (next.done) {
                continue;
            }
            const nextNode = createNode(next.value);
            if (isFragmentVNode(nextNode)) {
                yield* nextNode.children ?? [];
            }
            yield [nextNode];
        } while (!next.done);
    }
    async function* promiseGenerator(promise) {
        const result = await promise;
        yield [
            createNode(result, options, ...children$1)
        ];
    }
    function functionVNode(source) {
        const defaultOptions = {};
        const resolvedOptions = isDefaultOptionsO(defaultOptions) ? defaultOptions : options;
        const node = {
            reference: Fragment,
            source,
            options: resolvedOptions,
            children: replay(() => functionAsChildren()),
        };
        return node;
        async function* functionAsChildren() {
            const options = node.options;
            const source = node.source;
            // Lazy create the children when the function is first invoked
            // This allows children to be a bit more dynamic
            //
            // We will only provide a child to node.source if we have at least one child provided
            const child = node[Child] = node[Child] ?? children$1.length ? createNode(Fragment, {}, ...children$1) : undefined;
            // Referencing node here allows for external to update the nodes implementation on the fly...
            const nextSource = source(options, child);
            // If the nextSource is the same as node.source, then we should finish here, it will always return itself
            // If node.source returns a promise then we can safely assume this was intentional as a "loop" around
            // A function can also return an iterator (async or sync) that returns itself too
            //
            // This is to only detect hard loops
            // We will also reference the different dependency here, as they might have been re-assigned,
            // meaning the possible return from this function has changed, meaning the return value could be different
            const possibleMatchingSource = nextSource;
            if (possibleMatchingSource !== source ||
                source !== node.source ||
                options !== node.options ||
                child !== node[Child]) {
                yield* children(childrenContext, createNode(nextSource));
            }
        }
        function isDefaultOptionsO(value) {
            return value === defaultOptions && !options;
        }
    }
    function unmarshal(source) {
        if (isSourceReference(source)) {
            return sourceReferenceVNode(getReference(), source);
        }
        return {
            ...source,
            // Replace our reference if required
            reference: isSourceReference(source.reference) ? getMarshalledReference(source.reference) : getReference(source.options),
            children: source.children ? replay(() => asyncExtendedIterable(source.children).map(children => [...children].map(unmarshal))) : undefined
        };
    }
    function sourceReferenceVNode(reference, source, options, ...children$1) {
        return {
            reference: reference || getReference(options),
            scalar: !children$1.length,
            source,
            options,
            children: children$1.length ? replay(() => children(childrenContext, ...children$1)) : undefined
        };
    }
    function replay(fn) {
        return {
            [Symbol.asyncIterator]: () => fn()[Symbol.asyncIterator]()
        };
    }
}
function getMarshalledReference(reference) {
    return getReference({
        reference
    });
}
function getReference(options) {
    return getReferenceFromOptions(options) ?? Symbol("@virtualstate/fringe");
}
function isReferenceOptions(options) {
    function isReferenceOptionsLike(options) {
        return options && options.hasOwnProperty("reference");
    }
    return (isReferenceOptionsLike(options) &&
        isSourceReference(options.reference));
}
function getReferenceFromOptions(options) {
    if (!isReferenceOptions(options)) {
        return undefined;
    }
    return options.reference;
}

/**
 * `unique symbol` to represent a {@link FragmentVNode}, this will be used on the {@link VNode.reference} property.
 * The {@link FragmentVNode} should be ignored and the {@link VNode.children} values should be used in its place
 */
const Fragment = Symbol.for("@virtualstate/fringe/fragment");
function createFragment(options, ...children) {
    return createNode(Fragment, options, ...children);
}

function deferred$1() {
    let resolve = undefined, reject = undefined;
    const promise = new Promise((resolveFn, rejectFn) => {
        resolve = resolveFn;
        reject = rejectFn;
    });
    ok$1(resolve);
    ok$1(reject);
    return {
        resolve,
        reject,
        promise
    };
}
function ok$1(value) {
    if (!value) {
        throw new Error("Value not provided");
    }
}

function insertMany(list, afterIndex, many) {
    const entryArray = Array.from(many.entries());
    entryArray.forEach(([index, value], arrayIndex, array) => {
        const before = array[arrayIndex - 1];
        list.insert(before ? before[0] : afterIndex, index, value);
    });
}
class AbstractLinkedList {
    constructor(map, initial) {
        this.map = map;
        if (initial) {
            insertMany(this, undefined, initial);
        }
    }
    setMap(map) {
        this.map = map;
    }
    get(pointer) {
        return this.map.get(pointer);
    }
    set(pointer, value) {
        const node = this.get(pointer);
        if (node) {
            this.map.set(pointer, {
                ...node,
                value
            });
        }
        else {
            throw new Error("Pointer does not belong in this list");
        }
    }
    insert(after, pointer, value) {
        if (!after) {
            this.clear();
        }
        const reference = after && this.get(after);
        if (after && !reference) {
            throw new Error("Pointer does not belong in this list");
        }
        this.map.set(pointer, {
            value,
            next: reference ? reference.next : undefined
        });
        if (after) {
            this.map.set(after, {
                ...reference,
                next: pointer
            });
        }
    }
}

class WeakLinkedList extends AbstractLinkedList {
    constructor(initial) {
        super(new WeakMap(), initial);
    }
    clear() {
        this.setMap(new WeakMap());
    }
}

function defaultQueueMicrotask(fn) {
    if (typeof queueMicrotask === "function") {
        queueMicrotask(fn);
    }
    else if (typeof setImmediate === "function") {
        setImmediate(fn);
    }
    else {
        setTimeout(fn, 0);
    }
}
class InternalAbortError extends Error {
}
function noop() {
}
const START = Symbol("Start");
const END = Symbol("End");
const YIELD = Symbol("Yield");
class Collector {
    constructor(options = {}) {
        this.#active = true;
        this.#resolve = undefined;
        this.#promise = undefined;
        this.#rejection = deferred$1();
        this.#values = new WeakLinkedList();
        this.#pointer = {};
        this.#eagerPointer = undefined;
        this.#add = (value, queue = true) => {
            const pointer = this.#pointer;
            const node = this.#values.get(pointer);
            if (!node) {
                // Start things off
                this.#values.insert(undefined, pointer, START);
                return this.#add(value, queue);
            }
            const next = {};
            this.#values.insert(pointer, next, value);
            this.#pointer = next;
            if (queue) {
                this.#queueResolve();
            }
        };
        this.#constructPromise = () => {
            const defer = deferred$1();
            this.#promise = defer.promise;
            this.#resolve = defer.resolve;
            // Prevent uncaught exception
            this.#promise.catch(noop);
            // Pass on the rejection to our individual promise
            // If our promise is already resolved then this will have no effect
            this.#rejection.promise.catch(defer.reject);
        };
        this.#queueResolve = () => {
            if (!this.#resolve) {
                this.#constructPromise();
            }
            const resolve = this.#resolve;
            const promise = this.#promise;
            this.#resolve = undefined;
            this.queueMicrotask(() => {
                if (this.#promise === promise) {
                    this.#promise = undefined;
                }
                this.#add(YIELD, false);
                resolve();
            });
        };
        this.queueMicrotask = options.queueMicrotask || defaultQueueMicrotask;
        if (options.eagerCollection) {
            this.#eagerPointer = this.#pointer;
        }
        this.#yieldGroups = options.yieldGroups ?? false;
        // Catch early so if there is no iterators being utilised the process won't crash!
        this.#rejection.promise.catch(noop);
    }
    #active;
    #resolve;
    #promise;
    #rejection;
    #values;
    #pointer;
    #eagerPointer;
    #yieldGroups;
    add(value) {
        if (!this.#active)
            return;
        this.#add(value, true);
    }
    #add;
    #constructPromise;
    #queueResolve;
    close() {
        this.#active = false;
        this.#add(END);
        this.#rejection.reject(new InternalAbortError());
    }
    async *[Symbol.asyncIterator]() {
        if (!this.#active)
            return;
        const values = this.#values;
        const yieldGroups = this.#yieldGroups;
        let pointer = this.#pointer;
        // This will allow the first iterator to see all available values, rather than
        // the ones starting from the current final pointer of the linked list
        if (this.#eagerPointer) {
            pointer = this.#eagerPointer;
            // Clearing in the next microtask means any iterators added within this microtask will
            // get the eager pointer, where the next microtask this pointer will be gone
            this.queueMicrotask(() => this.#eagerPointer = undefined);
        }
        let promise = undefined, yielded = new WeakSet();
        try {
            do {
                let yielding;
                while ((yielding = compile()).length) {
                    yield yielding;
                }
                if (!this.#promise) {
                    this.#constructPromise();
                }
                promise = this.#promise;
                try {
                    // Its important to await here so we locally catch
                    // Once the promise has resolved we will hit our eager loop and yield the
                    // available values
                    await promise;
                }
                catch (error) {
                    if (isInternalAbortError(error)) {
                        break;
                    }
                    else {
                        yield Promise.reject(error);
                    }
                }
            } while (this.#active);
        }
        finally {
            const remaining = compile();
            if (remaining.length) {
                yield remaining;
            }
        }
        /**
         * Will compile an array of all values not yet yielded
         * All values returned will not be returned again by this iterator
         */
        function compile() {
            const array = [];
            let node = values.get(pointer);
            while (node = values.get(pointer)) {
                const value = node.value;
                if (!yielded.has(pointer)) {
                    yielded.add(pointer);
                    if (value === END)
                        break;
                    if (value === START)
                        continue;
                    // This will allow different iterators to get the same looking yield groups, excluding
                    // where values are available early
                    if (value === YIELD) {
                        if (!yieldGroups) {
                            continue;
                        }
                        if (array.length) {
                            break;
                        }
                        else {
                            // If we have no values to yield, move to the next yield group
                            continue;
                        }
                    }
                    array.push(value);
                }
                if (!node.next) {
                    break;
                }
                pointer = node.next;
            }
            return array;
        }
    }
}
function isInternalAbortError(error) {
    if (error instanceof InternalAbortError) {
        return true;
    }
    if (error instanceof AggregateError) {
        for (const aggregateError of error.errors) {
            if (isInternalAbortError(aggregateError)) {
                return true;
            }
        }
    }
    return false;
}

export { Collector as C, Fragment as F, isScalarVNode as a, isNativeVNode as b, isVNode as c, isMarshalledSourceReference as d, createNode as e, createFragment as f, isSourceReference as g, isFragmentVNode as i, union as u };
